<template>
  <GlPageWrap
    class="report-page__wrapper relative"
  >
    <div class="report__wrapper">
      <div
        class="report-search-control__wrapper"
        :class="{'gap-3 m-gap-2': coinData.isNeedTokensCall && (tokensList.length || txInfo2.contractsListData?.length) }"
      >
        <div class="report__header">
          <div class="report__header-coin-select">
            <GlCoinSelect
              dark
              :essential="appConfig.VUE_APP_ESSENTIAL_REPORT"
              slim
            />
          </div>
          <div class="report__header-search">
            <gl-search-box
              v-model="search"
              button-text="Search"
              dark-clear
              :disabled="!search"
              grey
              height="32px"
              :independent="isMobile || isTablet ? true : false"
              is-search
              :loading="calcLoading || addressDataLoading || txDataLoading"
              placeholder="Enter the address or tx hash"
              @search="searchData"
            />
          </div>
          <div class="report__header-requests">
            <gl-requests-counter />
          </div>
        </div>
        <transition name="slide">
          <div
            v-show="coinData.isNeedTokensCall && !tokensLoading && hasTxs"
          >
            <ChooseTokenList
              :address-hash="isEthAddressReport ? searchValue : txInfo2.tx_hash"
              :disable="calcLoading || addressDataLoading"
              :request-type="isEthAddressReport ? 'address' : 'tx'"
              :selected-token="selectedToken"
              :tokens-list="isEthAddressReport ? tokensList : txInfo2.contractsListData"
              @set-token="getReportByToken"
            />
          </div>
        </transition>
      </div>
      <div>
        <AddressReport
          v-if="addressReport"
          :address-data-loading="addressDataLoading"
          :address-info2="addressInfo2"
          :all-data-source="allDataSource"
          :all-data-source-by-owner="allDataSourceByOwner"
          :calculation-loading="calcLoading"
          :currencies-by-direction="currenciesByDirection"
          :download-loading="pdfLoading"
          :enable-download-items="enableDownloadItems"
          :profile-info2="profileInfo2"
          :pure-sources="pureSources"
          :selected-token="selectedToken"
          :source-configs-by-direction="sourceConfigsByDirection"
          :total-funds="addressTotalFunds"
          @export-report-to-csv="exportReportToCsv"
          @export-to-pdf="exportToPdf"
          @on-added-to-monitoring="onAddedToMonitoring"
        />
        <TxReport
          v-else-if="txReport"
          :all-data-source="allDataSource"
          :all-data-source-by-owner="allDataSourceByOwner"
          :calculation-loading="calcLoading"
          :currencies-by-direction="currenciesByDirection"
          :download-loading="pdfLoading"
          :enable-download-items="enableDownloadItems"
          :messages="messages"
          :pure-sources="pureSources"
          :selected-token="selectedToken"
          :source-configs-by-direction="sourceConfigsByDirection"
          :total-funds="txTotalFunds"
          :tx-data="txInfo"
          :tx-data-loading="txDataLoading"
          :tx-info2="txInfo2"
          @export-report-to-csv="exportReportToCsv"
          @export-to-pdf="exportToPdf"
        />
        <div
          v-else
          class="cyto-empty"
        >
          Type address or transaction hash in the search bar above to get sources of funds report.
        </div>
      </div>
    </div>
  </GlPageWrap>
</template>

<script>
// Vuex
import { mapActions, mapMutations, mapState, mapGetters } from "vuex";
//Components
import GlSearchBox from '@/components/gl-search-box'
import GlCoinSelect from "@/components/gl-coin-select";
import TxReport from "@/pages/report/components/TxReport";
import GlPageWrap from "@/components/layout/gl-page-wrap";
import AddressReport from "@/pages/report/components/AddressReport";
import ChooseTokenList from '@/pages/report/components/ChooseTokenList.vue'
// Utils
import axios from 'axios'
import config from "@/utils/appConfig";
import { csvExportReport } from '@/utils/export-data'
import { findFiatByKey } from "@/utils/format-by-price";
import { formatBtcAmount } from "@/utils/format-btc-amount";
import { formatShare, formatter } from "@/utils/sourcesFormatter";
import { sortingObjects, sourcesSortOrderArray } from "@/utils/sorting";
import {
  calcSourcesRiskPercent,
  formatterAmountValue,
  separateSources,
  accumulateValues,
  transformToTokenStructure,
} from '@/utils/report-data-formatter'
import { findColorByTypeScore, tokensSorting } from "@/utils/cytoskape-ui-rules";
import { featureAccess } from "@/utils/accesses";
import { toComaSeparate, restrictNumberAfterComma } from '@/utils/formatNumber';
import { spamMessagesCombine } from '@/utils/text-formatter';
import { errorsMessageListForRestrictPDFDownload } from '@/utils/errors';
import { txsAddPlus } from '@/utils/txs-add-plus'
import { formatDate } from '@/utils/format-date'
import { splitArrayByFixedSize } from '@/utils/helpers'
//mixins
import deviceWidthMixin from '@/assets/mixins/deviceWidthMixin'
import { AlertsMixin } from "@/assets/mixins/alertsMixin";
import momentTimezone from 'moment-timezone'
import GlRequestsCounter from '@/components/gl-requests-counter.vue'
import appConfig from '@/utils/appConfig'
// import appConfig from '@/utils/appConfig'

export default {
  components: {
    GlRequestsCounter,
    TxReport,
    GlPageWrap,
    GlSearchBox,
    GlCoinSelect,
    AddressReport,
    ChooseTokenList
  },
  mixins: [deviceWidthMixin, AlertsMixin],
  data() {
    return {
      search: '',
      addressInfo2: {},
      profileInfo2: {},
      txInfo: {},
      txInfo2: {},
      isEthTxReport: false,
      isAddressReport: false,
      isEthAddressReport: false,
      isTxReport: false,
      addressDataLoading: false,
      txDataLoading: false,
      calcLoading: false,
      tokensLoading: false,
      pdfLoading: false,
      allDataSource: {},
      allDataSourceByOwner: {},
      pureSources: {},
      currenciesByDirection: {},
      sourceConfigsByDirection: {},
      messages: [],
      tokensList: [],
      sourcesRiskPercent: 0,
      txRiskPercent: 0,
      addressTotalFunds: '',
      txTotalFunds: '',
      symbol: '',
      selectedToken: {
        symbol: '',
        address: '',
      },
      searchValue: '',
      currentScoreErrorMessage: {},
    }
  },
  computed: {
    ...mapState('user', ['userData']),
    ...mapState('analytics', ['coinType', 'coinData', 'currencyList']),
    ...mapGetters('directions', ['getActiveExposurePart', 'getExposurePartOptions', 'getActivePartBar']),
     appConfig() {
      return appConfig
    },
    enableGeneratePdf() {
      const resolveIncludedErrors = (object, array) => {
        const values = Object.values(object);
        return !!values.length && values.every(value => array.includes(value));
      };
      return config.VUE_APP_PDF_GENERATOR && !resolveIncludedErrors(this.currentScoreErrorMessage, errorsMessageListForRestrictPDFDownload)
    },
    hasReportData() {
      return Object.keys(this.addressInfo2).length !== 0 || Object.keys(this.txInfo2).length !== 0 || this.allDataSource?.incoming?.length || this.allDataSource?.outgoing?.length
    },
    addressReport() {
      return this.isAddressReport || this.isEthAddressReport
    },
    txReport() {
      return this.isTxReport || this.isEthTxReport
    },
    enableDownloadItems() {
      const items = []
      if (this.hasReportData && this.enableGeneratePdf) items.push('pdf')
      if (this.hasReportData && !this.calcLoading && !this.addressDataLoading && !this.txDataLoading) items.push('csv')
      return items
    },
    hasTxs() {
      return Boolean(
        this.pureSources?.incoming?.length ||
        this.pureSources?.outgoing?.length,
      )
    },
  },
  watch: {
    isDesktop: {
      handler(val) {
        this.setFieldInExposurePart('full', 'hide', Boolean(!val))
        this.setFieldInExposurePart('full', 'disabled', Boolean(!val))
        this.changeToAvailableExposurePart()
      },
      immediate: true
    },
  },
  mounted() {
    const { query } = this.$route

    if (query.type) {
      this.SET_COIN_TYPE(query.type)
      const coin = this.currencyList.find(curr => curr.key === query.type)
      this.SET_COIN_DATA(coin)
    }
    this.selectedToken.symbol = this.coinData.label
    this.selectedToken.address = this.$route.query.token || ''

    if (appConfig.VUE_APP_UNIFIED_TOKEN && this.coinData.family == 'eth') {
      this.selectedToken.address = this.$route.query.token ?? 'all'
      if (this.selectedToken.address == 'all') {
        this.selectedToken.symbol = "All assets"
      }
    }

    if (query.address) {
      this.search = query.address
      this.searchData(query.address)
      return
    }

    if (query.tx) {
      this.search = query.tx
      this.searchData(query.tx)
    }
  },
  methods: {
    ...mapActions({
      getTxEthData: 'analytics/getTxEthData',
      getTxAMLInfo: 'analytics/getTxAMLInfo',
      getAddressData: 'analytics/getAddressData',
      getTxRiskReport: 'analytics/getTxRiskReport',
      getAddressTokens: 'analytics/getAddressTokens',
      getAddressBalance: 'analytics/getAddressBalance',
      getAddressMetrics: 'analytics/getAddressMetrics',
      getEthAddressInfo: 'analytics/getEthAddressInfo',
      getTransactionInfo: 'analytics/getTransactionInfo',
      getTransactionTokens: 'analytics/getTransactionTokens',
      getAddressRiskReport: 'analytics/getAddressRiskReport',
      getAddressUOF: 'analytics/getAddressUOF',
      getReportAddress: 'analytics/getReportAddress',
      getTxUOF: 'analytics/getTxUOF',
      getReportTx: 'analytics/getReportTx',
      getTokens: 'analytics/getTokens',
    }),
    ...mapMutations('sidebar', ['SET_SHRUNK']),
    ...mapActions('entity', ['getEntityList']),
    ...mapMutations('analytics', ['SET_COIN_TYPE', 'SET_COIN_DATA']),
    ...mapActions('analytics', ['getAddressTokens', 'getTxEthScore', 'getAddressEthScore', 'getEthAddressInfo', 'getAddressBalance', 'getAddressMetrics', 'getTransactionTokens']),
    ...mapMutations('directions', ['SET_ACTIVE_EXPOSURE_PART', 'SET_ACTIVE_PART_BAR', 'SET_EXPOSURE_PART_OPTIONS']),
    formatter,
    formatShare,
    featureAccess,
    formatBtcAmount,
    tokensSorting,
    csvExportReport,
    findColorByTypeScore,
    formatterAmountValue,
    calcSourcesRiskPercent,
    findFiatByKey,
    toComaSeparate,
    spamMessagesCombine,
    txsAddPlus,
    formatDate,
    restrictNumberAfterComma,
    separateSources,
    accumulateValues,
    splitArrayByFixedSize,
    getCountryAndCity() {
      const timezone = momentTimezone.tz.guess();
      const zone = momentTimezone.tz.zone(timezone);
      return zone ? { timeZoneName: zone.name } : { error: "Unable to determine local time zone" };
    },
    formatterDepthSortValue(value) {
      if (typeof value === 'number') {
        return value
      }

      if (value && value.minimum && value.maximum) {
        return value.minimum
      } else {
        return null
      }
    },
    exportToPdf() {
      this.pdfLoading = true

      const { timeZoneName } = this.getCountryAndCity()

      axios({
        url: `${process.env.VUE_APP_DOCUMENT_GENERATOR_URL}/report/${
          this.addressReport ? 'address' : 'tx'
        }/${this.search}?userId=${this.userData.id}&currencyKey=${
          this.coinData.key
        }&tokenAddress=${this.selectedToken.address}&preferredCurrency=${
          findFiatByKey(this.userData.preferredCurrency).label
        }&timezone=${timeZoneName}&activePartBarValue=${this.getActivePartBar.value
        }&direction=${config.VUE_APP_SCORE_REQUEST_DIRECTION}`,
        method: 'GET',
        responseType: 'blob',
        headers: { ['x-api-key']: this.userData.apikey },
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `report_${this.search}.pdf`);
        document.body.appendChild(link);
        link.click();
      }).finally(() => this.pdfLoading = false);
    },
    getReportByToken(token) {

      if (this.isEthTxReport) {
        token = this.selectedToken.address === token.address ? {} : token
      }

      this.selectedToken = token

      const selectedTokenIndex = this.tokensList.findIndex(el => el.address === this.selectedToken.address)
      this.moveElementToTop(this.tokensList, selectedTokenIndex)
      const allTokenIndex = this.tokensList.findIndex(el => el.address === 'all')
      this.moveElementToTop(this.tokensList, allTokenIndex)

      this.searchData(this.search, false)
    },
    moveElementToTop(array, index) {
      if (index > array.length - 1 || index < 0) {
        // Index out of bounds
        return;
      }
      const element = array.splice(index, 1)[0];
      array.unshift(element);
    },
    loadMetrics(value) {
      this.loadingMetrics = true

      const sendData = {
        address: value,
        contract: this.selectedToken.address ? this.selectedToken.address : undefined,
        type: this.selectedToken.address ? 'tokens' : 'main'
      }

      this.getAddressMetrics(sendData).then(({ data: { totalTxs } }) => {
        this.addressInfo2 = {
          ...this.addressInfo2,
          totalTransactions: this.toComaSeparate(this.txsAddPlus(totalTxs.total, 2000000)) || '0',
          transactionsSent: this.toComaSeparate(this.txsAddPlus(totalTxs.outputs, 1000000)) || '0',
          transactionsReceived: this.toComaSeparate(this.txsAddPlus(totalTxs.inputs, 1000000)) || '0',
        }
      }).catch(() => this.addressInfo2.totalTransactions = 0).finally(() => {
        this.loadingMetrics = false
      })
    },
    async searchData(value, isNeedTokensCall = this.coinData.isNeedTokensCall) {
      this.SET_SHRUNK(true)

      this.selectToken(value)

      this.searchValue = value

      this.clearData()

      await this.SET_COIN_TYPE(this.coinType)

      if (this.coinData.family === 'eth' && new RegExp(this.coinData.addressRegex).test(value) && !new RegExp(this.coinData.txRegex).test(value)) {
        this.isEthAddressReport = true
        this.addressDataLoading = true
        this.calcLoading = true
        this.addressInfo2 = {}
        this.profileInfo2 = {}

        if (isNeedTokensCall) {
          await this.setAddressTokens(value)
        }

        await this.getEthAddressInfo(value).then(({ data, success }) => {
          if (success) {

            this.profileInfo2 = {
              ...this.profileInfo2,
              clusterData: { ...data.clusterData },
              ownerData: {
                owner: data.owner,
                description: data.description,
                type: data.type,
                tags: data.tags,
                meta: data.meta,
              },
            }

            this.loadMetrics(value)

            this.addressInfo2 = {
              ...this.addressInfo2,
              coinKey: this.coinData.key,
              coinFamily: this.coinData.family,
              createdAt: Date.now(),
              address: data.address,
              isMonitoring: data.isMonitoring,
              addressType: data.addressType,
              balance: this.toComaSeparate(String(this.formatterAmountValue(data.balance, this.selectedToken.decimals ?? this.coinData.decimals, this.selectedToken.symbol ?? this.coinData.label))),
              price: data.price,
            }

            try {this.gettingEntityByOwner(data?.owner)} catch (e) {console.log(e)}

          }
        }).catch(() => {
          this.addressDataLoading = false
        })

        const isAllSelected = this.selectedToken.address == 'all'

        this.getAddressBalance({
          address: value,
          contract: isAllSelected ? undefined : this.selectedToken.address || undefined })
        .then(({ data }) => {

          this.addressInfo2 = {
            ...this.addressInfo2,
            balance: `${this.toComaSeparate(String(this.restrictNumberAfterComma(data?.balance?.amount, 8) || 0))} ${this.selectedToken.address && this.selectedToken.address !== 'all' ?  data?.balance?.symbol || this.selectedToken.symbol : this.coinData.label}`,
            price: data?.balance?.price,
            priceTimestamp: data?.balance?.priceTimestamp || null,
          }

        }).finally(() => {
          this.addressDataLoading = false
        })


        await this.getReportAddress({ address: this.search, token: isAllSelected ? undefined : this.selectedToken.address, tokenTicker: isAllSelected ? undefined : this.selectedToken.symbol } ).then(async ({ data, success }) => {

          if (!success && data?.meta) {
            this.$toasted.global.info({ message: this.spamMessagesCombine({ meta: data?.meta }) })
          }

          if (data?.message) {
            this.$toasted.global.error({ message: data?.message })
            this.$set(this.currentScoreErrorMessage, 'global', data?.message)
          }

          if (success) {
            const { sources, isMonitoring, totalFunds, selfFunding }  = data

            if (!sources.length && selfFunding > 0) {
              this.$toasted.global.info({ message: this.spamMessagesCombine({ selfFunding }) })
            }

            this.addressInfo2.isMonitoring = isMonitoring

            const uniqueSwapTokensListResponse = await this.getUniqueSwapTokensList(sources) || []

            const currencies = transformToTokenStructure([...this.tokensList, ...uniqueSwapTokensListResponse.flat() ])

            const { sofSources, uofSources } = this.separateSources(sources)

            this.setAddressSources({ sources: sofSources, currencies }, 'incoming')
            this.setAddressSources({ sources: uofSources, currencies }, 'outgoing')


            if (this.featureAccess('ALERTS') && new RegExp(this.coinData.addressRegex).test(value)) {
              this.setAlertFlags(value)
            }
            this.addressTotalFunds = totalFunds ?? null;
          }
        }).catch(() => {
          this.calcLoading = false
        }).finally(() => {
          this.calcLoading = false
          this.sourcesRiskPercent = this.calcSourcesRiskPercent(this.allDataSource.incoming)
          this.setFieldInExposurePart('incoming', 'disabled', Boolean(!this.allDataSource?.incoming?.length))
          this.setFieldInExposurePart('outgoing', 'disabled', Boolean(!this.allDataSource?.outgoing?.length))
          this.changeToAvailableExposurePart()
        })

        if (!this.selectedToken.address && !this.allDataSource?.incoming?.length && !this.allDataSource?.outgoing?.length && this.tokensList.length > 0 && this.tokensList.find(contract => contract.address)) {
          this.selectedToken = this.tokensList.find(contract => contract.address)
          await this.searchData(this.search)
        }

        if (this.$route.name === 'report') {
          await this.$router.push({ name: 'report', query: { type: this.coinType, address: value, token: this.selectedToken.address } }).catch(e => e)
        }

        return
      }

      if (this.$can('use', this.coinData.family)  && this.coinData.family === 'eth' && new RegExp(this.coinData.txRegex).test(value)) {
        this.isEthTxReport = true
        this.txDataLoading = true
        this.calcLoading = true

        if (this.selectedToken.address == 'all') {
          this.selectedToken = {
            symbol: this.coinData.label,
            address: ""
          }
        }

        this.txInfo = {}
        this.txInfo2 = {}

        if (this.coinType && !this.selectedToken.address) this.selectedToken.symbol = this.coinData.label

        const a = await this.getTransactionTokens({ tx: this.search }).then(({ data, success }) => {
          if (!success) this.calcLoading = false
          if (data.message) {
            this.txInfo = {}
            this.txInfo2 = {}
            this.$toasted.global.error({message: `${data.message}`})
          }
          const formattedTokensList = data.map(token => {
            return {
              ...token,
              name: token.symbol
            }
          })

          this.txInfo2.contractsListData = tokensSorting(formattedTokensList)
          this.txInfo.contractsListData = tokensSorting(formattedTokensList)
          // this.txInfo.contractsListData.unshift({ address: '', symbol: this.coinData.label, icon: getNativeCoinIcon(this.coinData.key) })

          if (this.txInfo2.contractsListData.find(el => el.address === this.selectedToken.address)) {
            this.selectedToken = {
              ...this.txInfo2.contractsListData.find(el => el.address === this.selectedToken.address),
              decimals: this.selectedToken.address ? this.txInfo2.contractsListData.find(el => el.address === this.selectedToken.address).decimals || 0 : this.coinData.decimals
            }
          } else if (!this.selectedToken.address) {
            this.selectedToken = {
              ...this.selectedToken,
              decimals: this.selectedToken.decimals ?? this.coinData.decimals ?? 0
            }
          }

          if (this.txInfo.contractsListData.find(el => el.address === this.selectedToken.address)) {
            this.selectedToken = {
              ...this.txInfo.contractsListData.find(el => el.address === this.selectedToken.address),
              decimals: this.selectedToken.address ? this.txInfo.contractsListData.find(el => el.address === this.selectedToken.address).decimals || 0 : this.coinData.decimals
            }
          } else {
            if (!this.selectedToken.address) {
              this.selectedToken = {
                ...this.selectedToken,
                decimals: this.selectedToken.decimals ?? this.coinData.decimals ?? 0
              }
            }
          }
        }).catch(() => {
          this.calcLoading = false
        })

        const b = await this.getTxEthData({ tx: value }).then(({ data, success }) => {

          if (!success) this.calcLoading = false

          if (data.message) {
            this.txInfo = {}
            this.txInfo2 = {}
            this.$toasted.global.error({message: `${data.message}`})
          }
          const localTxData = data?.txs[0] || {}

          this.txInfo2 = {
            ...this.txInfo2,
            timestamp: localTxData.timestamp,
            coinKey: this.coinData.key,
            coinFamily: this.coinData.family,
            blockHeight: this.toComaSeparate(localTxData.blockHeight),
            createdAt: Date.now(),
            tx_hash: localTxData.tx_hash || this.search,
          }

          this.txInfo = {
            ...this.txInfo,
            ...localTxData,
            tx_hash: this.search,
          }
        }).finally(() => {
          this.txDataLoading = false
        })

        const c = this.getReportTx({ tx_hash: this.search, token: this.selectedToken.address, tokenTicker: this.selectedToken.symbol })
          .then(async ({ data, success}) => {

            if (!success) this.calcLoading = false

            if (!success && data?.meta) {
              this.$toasted.global.info({ message: this.spamMessagesCombine({ meta: data?.meta }) })
            }

            if (data?.message) {
              this.$toasted.global.error({message: `${data?.message}`})
            }

            this.$set(this.currentScoreErrorMessage, 'global', data?.message)

            if (success) {
              const { sources, symbol, totalFunds  } = data

              const uniqueSwapTokensListResponse = await this.getUniqueSwapTokensList(sources) || []

              const currencies = transformToTokenStructure([...this.txInfo2.contractsListData, ...uniqueSwapTokensListResponse.flat() ])

              const { sofSources, uofSources } = this.separateSources(sources)

              this.setTxSources({ sources: sofSources, symbol, currencies }, 'incoming')
              this.setTxSources({ sources: uofSources, symbol, currencies }, 'outgoing')

              this.txInfo2 = {
                ...this.txInfo2,
                price: this.accumulateValues({dataSource: sofSources, key: 'amountCur' }),
                formattedTotalAmount: this.accumulateValues({dataSource: sofSources, key: 'amount', formatterValue: this.formatterAmountValue, coinData: this.coinData , selectedToken: this.selectedToken}),
              }
              this.txTotalFunds = totalFunds
            }

          }).catch(() => {
            this.calcLoading = false
          }).finally(() => {
            this.calcLoading = false
            this.txRiskPercent = this.calcSourcesRiskPercent(this.allDataSource.incoming)
            this.setFieldInExposurePart('incoming', 'disabled', Boolean(!this.allDataSource?.incoming?.length))
            this.setFieldInExposurePart('outgoing', 'disabled', Boolean(!this.allDataSource?.outgoing?.length))
            this.changeToAvailableExposurePart()
          })

        await Promise.allSettled([a, b, c]).finally(() => {
          this.calcLoading = false
        })

        if (!this.selectedToken.address && !this.allDataSource?.incoming?.length && !this.allDataSource?.outgoing?.length && this.txInfo.contractsListData.length && this.txInfo.contractsListData.find(contract => contract.address)) {
          this.selectedToken = this.txInfo.contractsListData.find(contract => contract.address)
          await this.searchData(this.search)
        }
        await this.$router.push({ name: 'report', query: { tx: value, type: this.coinType, token: this.selectedToken.address } }).catch(e => e)
        return
      }

      if (this.$can('use', this.coinData.family) && new RegExp(this.coinData.txRegex).test(value)) {
        this.isTxReport = true
        this.txDataLoading = true
        this.calcLoading = true
        this.txInfo2 = {}

        const a = this.getTransactionInfo({ txHash: value }).then(({ data }) => {
          if (data.message) {
            this.$toasted.global.error({message: `${data.message}`})
          }
          this.txInfo2 = {
            ...this.txInfo2,
            coinKey: this.coinData.key,
            coinFamily: this.coinData.family,
            timestamp: data.timestamp,
            totalInputs: this.toComaSeparate(data.totalInputs),
            totalOutputs: this.toComaSeparate(data.totalOutputs),
            blockHeight: this.toComaSeparate(data.blockHeight),
            createdAt: Date.now(),
            tx_hash: data.tx_hash || this.search,
            amount: data.inputsAmount,
            price: data.inputsAmountPrice,
            priceTimestamp: data.inputsAmountPriceTimestamp,
            formattedTotalAmount: this.toComaSeparate(String(this.formatBtcAmount(data.inputsAmount, true))) || '0'
          }

          this.txInfo = {
            ...data,
            formattedTotalAmount: this.toComaSeparate(String(this.formatBtcAmount(data.inputsAmount, true))) || '0'
          }
          this.txDataLoading = false
        })
        const b = this.getTxAMLInfo({ tx_hash: value })
          .then(({ data }) => {
            this.messages = data.messages
          }).catch(({response: {data}}) => {
            if (data.statusCode && data.statusCode !== 500) {
              this.$toasted.global.error({message: `${data.message}`})
            }
          })

        const c = this.getReportTx({ tx_hash: value })
          .then(({ data, success }) => {

            if (!success) this.calcLoading = false

            if (!success && data?.meta) {
              this.$toasted.global.info({ message: this.spamMessagesCombine({ meta: data?.meta }) })
            }

            if (data?.message) {
              this.$toasted.global.error({message: `${data?.message}`})
            }

            this.$set(this.currentScoreErrorMessage, 'global', data?.message)

            if (success) {
              const { sources, symbol, currencies, totalFunds  } = data

              const { sofSources, uofSources } = this.separateSources(sources)

              this.setTxSources({ sources: sofSources, symbol, currencies }, 'incoming')
              this.setTxSources({ sources: uofSources, symbol, currencies }, 'outgoing')
              this.txTotalFunds = totalFunds
            }
          }).catch(() => {
            this.calcLoading = false
          }).finally(() => {
            this.setFieldInExposurePart('incoming', 'disabled', Boolean(!this.allDataSource?.incoming?.length))
            this.setFieldInExposurePart('outgoing', 'disabled', Boolean(!this.allDataSource?.outgoing?.length))
            this.changeToAvailableExposurePart()
          })

        await Promise.allSettled([a, b, c]).catch(() => { this.calcLoading = false }).finally(() => {
          this.calcLoading = false
          this.txRiskPercent = this.calcSourcesRiskPercent(this.allDataSource.incoming)
        })

        await this.$router.push({ name: 'report', query: { tx: value, type: this.coinType } }).catch(e => e)
        return
      }

      if (this.$can('use', this.coinData.family) && new RegExp(this.coinData.addressRegex).test(value) ) {
        this.isAddressReport = true
        this.addressDataLoading = true
        this.calcLoading = true
        this.getAddressData({ address: value }).then(({data, success}) => {
          if (!success) {
            this.$toasted.global.error({message: `${data.message}`})
            return
          }

          this.profileInfo2 = {
            ...this.profileInfo2,
            clusterData: { ...data.clusterData },
            ownerData: {
              owner: data.owner,
              description: data.description,
              type: data.type,
              tags: data.tags,
              meta: data.meta,
            },
          }

          this.addressInfo2 = {
            ...this.addressInfo2,
            coinKey: this.coinData.key,
            coinFamily: this.coinData.family,
            createdAt: Date.now(),
            address: data.address,
            isMonitoring: data.isMonitoring,
            addressType: 'address',
            totalTransactions: this.toComaSeparate(this.txsAddPlus(data.txCount, 2000000)) || '0',
            transactionsSent: this.toComaSeparate(this.txsAddPlus(data.txSentCount, 1000000)) || '0',
            transactionsReceived: this.toComaSeparate(this.txsAddPlus(data.txReceivedCount, 1000000)) || '0',
            balance: this.toComaSeparate(String(this.formatterAmountValue(data.balance, this.selectedToken.decimals ?? this.coinData.decimals, this.selectedToken.symbol ?? this.coinData.label))),
            price: data.price,
            totalSent: this.toComaSeparate(String(this.formatterAmountValue(data.amountSent, this.selectedToken.decimals ?? this.coinData.decimals, this.selectedToken.symbol ?? this.coinData.label))),
            totalSentPrice: data.amountSentPrice,
            totalSentPriceTimestamp: data.amountSentPriceTimestamp,
            totalReceived: this.toComaSeparate(String(this.formatterAmountValue(data.amountReceived, this.selectedToken.decimals ?? this.coinData.decimals, this.selectedToken.symbol ?? this.coinData.label))),
            totalReceivedPrice: data.amountReceivedPrice,
            totalReceivedPriceTimestamp: data.amountReceivedPriceTimestamp,
            firstSeen: data.firstSeen ? this.formatDate(data.firstSeen * 1000, 'dd.MM.yyyy HH:mm') : '',
            lastSeen: data.firstSeen ? this.formatDate(data.lastSeen * 1000, 'dd.MM.yyyy HH:mm') : '',
          }

          try {
            this.gettingEntityByOwner(data?.owner)
            this.gettingEntityByOwner(data?.clusterData?.owner, false)
          } catch (e) {
            console.log(e);
          }
        })
          .finally(() => {
            this.addressDataLoading = false
          })

        await this.getReportAddress({ address: value }).then(({ data, success }) => {

          if (!success && data?.meta) {
            this.$toasted.global.info({ message: this.spamMessagesCombine({ meta: data?.meta }) })
          }

          if (data?.message) {
            this.$toasted.global.error({ message: data?.message })
            this.$set(this.currentScoreErrorMessage, 'global', data?.message)
          }

          if (success) {
            const { sources, currencies, isMonitoring, totalFunds, selfFunding }  = data

            if (!sources.length && selfFunding > 0) {
              this.$toasted.global.info({ message: this.spamMessagesCombine({ selfFunding }) })
            }

            this.addressInfo2.isMonitoring = isMonitoring

            const { sofSources, uofSources } = this.separateSources(sources)

            this.setAddressSources({ sources: sofSources, currencies }, 'incoming')
            this.setAddressSources({ sources: uofSources, currencies }, 'outgoing')

            if (this.featureAccess('ALERTS') && new RegExp(this.coinData.addressRegex).test(value)) {
              this.setAlertFlags(value)
            }
            this.addressTotalFunds = totalFunds ?? null;
          }
        }).catch(() => {
          this.calcLoading = false
        }).finally(() => {
          this.calcLoading = false
          this.sourcesRiskPercent = this.calcSourcesRiskPercent(this.allDataSource.incoming)
          this.setFieldInExposurePart('incoming', 'disabled', Boolean(!this.allDataSource?.incoming?.length))
          this.setFieldInExposurePart('outgoing', 'disabled', Boolean(!this.allDataSource?.outgoing?.length))
          this.changeToAvailableExposurePart()

        })

        await this.$router.replace({ name: 'report', query: { address: value, type: this.coinType } }).catch((err) => err)
        return
      }
      this.$toasted.global.error({ message: 'Search value is not valid'})
    },
    clearData() {
      this.isAddressReport = false
      this.isTxReport = false
      this.isEthTxReport = false
      this.isEthAddressReport = false

      this.addressInfo2 = {}
      this.profileInfo2 = {}
      this.txInfo = {}
      this.txInfo2 = {}

      this.allDataSource = {}
      this.allDataSourceByOwner = {}

      this.pureSources = {}
      this.currenciesByDirection = {}
      this.sourceConfigsByDirection = {}

      this.sourcesRiskPercent = 0
      this.txRiskPercent = 0
      this.addressTotalFunds = ''
      this.txTotalFunds = ''
      this.currentScoreErrorMessage = {}

      this.SET_ACTIVE_EXPOSURE_PART(this.getExposurePartOptions[0])
    },
    setTxSources({sources = [], symbol = '', currencies = {},}, activeExposurePart) {
      this.symbol = symbol

      sources = sources.map((source) => {
        const localCurrency = currencies[source.currency]
          ? { ...currencies[source.currency], decimals: currencies[source.currency].decimals || 0 }
          : {}

        return {
          ...source,
          share: sources.length === 1 ? 1 : source.share,
          currencyData: localCurrency,
          formattedAmount: this.toComaSeparate(String(this.formatterAmountValue(source.amount, localCurrency?.decimals ?? this.coinData.decimals, localCurrency?.currency || this.coinData.label))),
          depthSortValue: this.formatterDepthSortValue(source.depth)
        }
      })

      this.$set(this.currenciesByDirection, activeExposurePart, currencies)
      this.$set(this.pureSources, activeExposurePart, sources)

      if (this.sourceConfigsByDirection[activeExposurePart]) {
        this.$set(this.sourceConfigsByDirection[activeExposurePart], 'hasOnlyOneSource', sources.length === 1);
      } else {
        this.$set(this.sourceConfigsByDirection, activeExposurePart, { hasOnlyOneSource: sources.length === 1 });
      }

      const groupedSourcesByType = sortingObjects(formatter(sources, 'funds.type'), sourcesSortOrderArray)

      this.$set(this.allDataSource, activeExposurePart, groupedSourcesByType.map(item => ({
        ...item,
        funds: {
          ...item.funds,
          default: Boolean(item.funds.default)
        },
        key: item.funds.type,
        tooltip: `${item.funds.type} ${formatShare(item.share)}`,
        pieValue: item.share,
        value: item.share,
        itemStyle: {color: item.funds.default ? this.findColorByTypeScore(-1) : this.findColorByTypeScore(item.funds.score)},
        formattedAmount: this.selectedToken.address === 'all' ? '' : this.toComaSeparate(String(this.formatterAmountValue(item.amount, item?.currencyData?.decimals ?? this.coinData.decimals, item?.currencyData?.currency || this.coinData.label)))
      })))

      const groupedSourcesByOwner = sortingObjects(formatter(sources, 'owner'), sourcesSortOrderArray)

      this.$set(this.allDataSourceByOwner, activeExposurePart, groupedSourcesByOwner.map(item => ({
        ...item,
        funds: {
          ...item.funds,
          default: Boolean(item.funds.default)
        },
        key: item.owner,
        tooltip: `${item.owner} ${formatShare(item.share)}`,
        pieValue: item.share,
        value: item.share,
        itemStyle: {color: item.funds.default ? this.findColorByTypeScore(-1) : this.findColorByTypeScore(item.funds.score)},
        formattedAmount: this.selectedToken.address === 'all' ? '' : this.toComaSeparate(String(this.formatterAmountValue(item.amount, item?.currencyData?.decimals ?? this.coinData.decimals, item.currencyData.currency || this.coinData.label)))
      })))

      this.gettingEntityByOwners(this.pureSources[activeExposurePart]?.map(el => (el.owner)), activeExposurePart)
    },
    setAddressSources({sources = [], currencies = {}}, activeExposurePart) {

      sources = sources.map((source) => {
        const localCurrency = currencies[source.currency]
          ? { ...currencies[source.currency], decimals: currencies[source.currency].decimals || 0 }
          : {}
        return {
          ...source,
          share: sources.length === 1 ? 1 : source.share,
          currencyData: localCurrency,
          formattedAmount: this.toComaSeparate(String(this.formatterAmountValue(source.amount, localCurrency?.decimals ?? this.coinData.decimals, localCurrency?.currency ?? this.coinData.label))),
          depthSortValue: this.formatterDepthSortValue(source.depth)
        }
      })

      this.$set(this.currenciesByDirection, activeExposurePart, currencies)
      this.$set(this.pureSources, activeExposurePart, sources)

      if (this.sourceConfigsByDirection[activeExposurePart]) {
        this.$set(this.sourceConfigsByDirection[activeExposurePart], 'hasOnlyOneSource', sources.length === 1);
      } else {
        this.$set(this.sourceConfigsByDirection, activeExposurePart, { hasOnlyOneSource: sources.length === 1 });
      }

      const groupedSourcesByType = sortingObjects(formatter(sources, 'funds.type'), sourcesSortOrderArray)

      this.$set(this.allDataSource, activeExposurePart, groupedSourcesByType.map(item => ({
        ...item,
        funds: {
          ...item.funds,
          default: Boolean(item.funds.default)
        },
        key: item.funds.type,
        tooltip: `${item.funds.type} ${formatShare(item.share)}`,
        pieValue: item.share,
        value: item.share,
        itemStyle: {color: item.funds.default ? this.findColorByTypeScore(-1) : this.findColorByTypeScore(item.funds.score)},
        formattedAmount: this.selectedToken.address === 'all' ? '' : this.toComaSeparate(String(this.formatterAmountValue(item.amount, item?.currencyData?.decimals ?? this.coinData.decimals, item.currencyData.currency || this.coinData.label)))
      })))

      const groupedSourcesByOwner = sortingObjects(formatter(sources, 'owner'), sourcesSortOrderArray)

      this.$set(this.allDataSourceByOwner, activeExposurePart, groupedSourcesByOwner.map(item => ({
        ...item,
        funds: {
          ...item.funds,
          default: Boolean(item.funds.default)
        },
        key: item.owner,
        tooltip: `${item.owner} ${formatShare(item.share)}`,
        pieValue: item.share,
        value: item.share,
        itemStyle: {color: item.funds.default ? this.findColorByTypeScore(-1) : this.findColorByTypeScore(item.funds.score)},
        formattedAmount: this.selectedToken.address === 'all' ? '' : this.toComaSeparate(String(this.formatterAmountValue(item.amount, item?.currencyData?.decimals ?? this.coinData.decimals, item.currencyData.currency || this.coinData.label)))
      })))

      this.gettingEntityByOwners(this.pureSources[activeExposurePart]?.map(el => (el.owner)), activeExposurePart)
    },
    setAlertFlags(value) {
      this.loadAlerts(value).then((getAlertsByIdsData) => {
        const filteredAlerts = getAlertsByIdsData.filter(alert => alert.count > 0);
        this.$set(this.addressInfo2, 'flagsData', filteredAlerts || [])
      });
    },
    changeToAvailableExposurePart() {
      const availableExposurePart = this.getExposurePartOptions.find(part => !part.disabled)
      if (this.getActiveExposurePart?.value !== availableExposurePart?.value) {
        this.SET_ACTIVE_EXPOSURE_PART(availableExposurePart)
      }
    },
    setFieldInExposurePart(activeExposurePart, field, value) {
      const opIndex = this.getExposurePartOptions.findIndex(({ value }) => value === activeExposurePart)
      if (opIndex !== -1) {
        let options = this.getExposurePartOptions
        options[opIndex][field] = value
        this.SET_EXPOSURE_PART_OPTIONS(options)
      }
    },
    changeExposureActivePart(item) {
      this.activeExposurePart= item
    },
    changeActivePartBarValue(item) {
      this.activePartBar= item
    },
    gettingEntityByOwner(owner, isAddress = true) {
      if (owner) {
        this.getEntityList({ name: owner }).then((data) => {
          if (data?.items?.find(el => el?.name?.value?.toLowerCase() === owner?.toLowerCase())) {
            const entityId = data?.items?.find(el => el?.name?.value.toLowerCase() === owner.toLowerCase())?.id || null
            if (isAddress) {
              this.$set(this.profileInfo2.ownerData, 'entityId', entityId);
            } else {
              this.$set(this.profileInfo2.clusterData, 'entityId', entityId);
            }
          }
        })
      }
    },
    gettingEntityByOwners(owners, activeExposurePart) {
      if (owners && owners.length) {
        this.getEntityList({ names: [...new Set(owners)] }).then((data) => {

          this.allDataSourceByOwner[activeExposurePart] = this.allDataSourceByOwner[activeExposurePart]?.map(sources => {
            const entityId = data?.items?.find(el => el?.name?.value?.toLowerCase() === sources.owner?.toLowerCase())?.id || null
            return { ...sources, entityId }
          })
          this.pureSources[activeExposurePart] = this.pureSources[activeExposurePart]?.map(sources => {
            const entityId = data?.items?.find(el => el?.name?.value?.toLowerCase() === sources.owner?.toLowerCase())?.id || null
            return { ...sources, entityId }
          })

        })
      }
    },
    async setAddressTokens(value) {
      this.tokensLoading = true

      await this.getAddressTokens({ address: value }).then(({ data, success }) => {
          if (!success) {
            this.$toasted.global.error({message: `${data.message}`})
          }

          this.tokensList = this.tokensSorting(data)
          // this.tokensList.unshift({ address: '', symbol: this.coinData.label, icon: getNativeCoinIcon(this.coinData.key) })

          if (appConfig.VUE_APP_UNIFIED_TOKEN) {
            this.tokensList.unshift({address: 'all', symbol: 'All assets'})
          }

          if (this.$route.query.token) {
            this.selectedToken = this.tokensList.find(el => el.address === this.$route.query.token)
            if (!this.selectedToken) {
              this.selectToken(value)
              this.$router.push({ name: 'report', query: { type: this.coinType, address: value, token: this.selectedToken.address } }).catch(e => e)
            }
          }

          if (this.selectedToken.address) {
            const selectedTokenIndex = this.tokensList.findIndex(el => el.address === this.selectedToken.address)
            this.moveElementToTop(this.tokensList, selectedTokenIndex)
            const allTokenIndex = this.tokensList.findIndex(el => el.address === 'all')
            this.moveElementToTop(this.tokensList, allTokenIndex)
          }

        }).catch(() => {
          this.calcLoading = false
        }).finally(() => {
          this.tokensLoading = false
        })
    },
    selectToken(value) {
      if (!this.coinData.isNeedTokensCall) {
        this.selectedToken = {
          symbol: this.coinData.label,
          address: '',
        }
      }
      if (!this.selectedToken || (!this.selectedToken.address && this.selectedToken.symbol !== this.coinData.label) || (this.searchValue && this.searchValue !== value)) {
        if (appConfig.VUE_APP_UNIFIED_TOKEN && this.coinData.isNeedTokensCall) {
          this.selectedToken = {
            symbol: 'All assets',
            address: 'all',
          }
        } else {
          this.selectedToken = {
            symbol: this.coinData.label,
            address: '',
          }
        }
      }
    },
    getSwapTokens(sources) {
      const allTokens = sources.reduce((acc, current) => {
        if (current.listType == 'Swap') {
          const currencyInputObject = current?.inputAmounts?.find(item => item?.currency) || current?.inputAmounts[0];
          const currencyOutputObject = current?.outputAmounts?.find(item => item.currency) || current?.outputAmounts[0]
          acc.push(currencyInputObject.currency, currencyOutputObject.currency)
        }
        return acc
      }, []).filter(item => item)

     return [...new Set(allTokens)]
    },
    async getUniqueSwapTokensList(sources) {
      try {
        const uniqueSwapTokensList = [...new Set([ ...this.getSwapTokens(sources), ...this.tokensList.map(token => token.currency)])].filter(item => item)

        if (!uniqueSwapTokensList.length) return []

        return await Promise.all(this.splitArrayByFixedSize(uniqueSwapTokensList, 200).map(async tokensPart => {
          const response = await this.getTokens({supported: false, token: tokensPart })
          return response;
        })) || [];

      } catch (error) {
        console.error(error.message);
      }
    },
    onAddedToMonitoring(target) {
     this.searchData(target)
    },
    exportReportToCsv() {
      if (this.isAddressReport || this.isEthAddressReport) {
        this.csvExportReport({ 
          reportType: 'address',
          coinData: this.coinData,
          addressInfo: this.addressInfo2, 
          allDataSource: this.allDataSource, 
          allDataSourceByOwner: this.allDataSourceByOwner, 
          currenciesByDirection: this.currenciesByDirection, 
          profileInfo: this.profileInfo2, 
          pureSources: this.pureSources, 
          selectedToken: this.selectedToken, 
          totalFunds: this.addressTotalFunds, 
        })
      } else if (this.isTxReport || this.isEthTxReport) {
        this.csvExportReport({ 
          reportType: 'tx',
          coinData: this.coinData,
          txInfo: this.txInfo2, 
          allDataSource: this.allDataSource, 
          allDataSourceByOwner: this.allDataSourceByOwner, 
          currenciesByDirection: this.currenciesByDirection, 
          pureSources: this.pureSources, 
          selectedToken: this.selectedToken, 
          totalFunds: this.txTotalFunds, 
        })
      }
    },
  }
}
</script>

<style>
.report-page__wrapper {
  padding: 24px;
  background: var(--cotton-grey-f-9);
}

.report__wrapper {
  border-radius: 3px;
  padding-bottom: 70px;
}

.report-search-control__wrapper {
  display: flex;
  flex-direction: column;
  padding: 24px;
  background-color: var(--white);
  border-radius: 16px;
  border: 1px solid var(--Accent-Coton-Grey);
  margin-bottom: 25px;
}


/* Report header */
.report__header {
  display: flex;
  align-items: center;
  gap: 16px;
  background-color: var(--white);
}

.report__header-coin-select {
  max-width: 100px;
  min-width: 100px;
}
.report__header-coin-select .v-select {
  margin-right: 0 !important;
  border-radius: 6px;
}
.report__header-coin-select .vs__dropdown-toggle {
  min-height: 32px;
  border-radius: 6px;
}
.report__header-coin-select .coin-select-base  {
  margin-right: 0 !important;
}
.report__header-coin-select .coin-select-base__dark .vs__dropdown-toggle {
  min-width: 0 !important;
}

.report__header-search {
  flex: 1 0 auto;
}
.report__header-search .gl-search-box {
  gap: 16px;
}
.report__header-search .gl-search-box .gl-input__input {
  min-width: 100px !important;
  border-radius: 6px;
}
.report__header-search .gl-search-box .gl-button {
  text-transform: capitalize;
  width: 180px;
}
.report__header-search .gl-search-box .gl-icon--pp-search {
  margin-right: 6px !important;
}

.report__header-requests {
  padding-left: 8px;
}
.report__header-requests .gl-requests-counter__title {
  font-size: 14px;
}
.report__header-requests .gl-requests-counter__sub-title {
  font-size: 10px;
}

@media (max-width: 767px) {
  .report-page__wrapper {
    padding: 16px;
  }
  .report-search-control__wrapper {
    display: flex;
    flex-direction: column;
    padding: 8px;
    margin-bottom: 16px;
  }

  .report__header {
    gap: 8px 16px;
    margin-bottom: 10px;
    margin-left: -10px;
    margin-right: -10px;
    padding: 8px 18px;
    border-radius: 0;
  }
  .report__header-requests {
    padding-left: 0;
  }
}
@media (max-width: 579px) {
  .report__header {
    flex-wrap: wrap;
  }
  .report__header-coin-select {
    max-width: 100%;
    flex: 1 0 auto;
    order: 1;
  }
  .report__header-search {
    order: 3;
    min-width: 100%;
  }
  .report__header-requests {
    flex: 0 0 auto;
    order: 2;
  }
}
 
</style>

<template>
  <div>
    <TopAddressActions
      :calculation-loading="calculationLoading"
      class="report-top-actions__wrapper"
      :info="addressInfo2"
      :loading="addressDataLoading"
      @on-added-to-monitoring="$emit('on-added-to-monitoring', $event)"
    />
    <AddressInfoWrapper
      :has-additional-info="addressInfo2.coinFamily == 'btc'"
      :info="addressInfo2"
      :loading="addressDataLoading"
    />
    <BottomInfoActions
      class="mb-4"
      :download-loading="downloadLoading"
      :enable-download-items="enableDownloadItems"
      :info="addressInfo2"
      :loading="addressDataLoading"
      report-type="address"
      @export-report-to-csv="$emit('export-report-to-csv')"
      @export-to-pdf="$emit('export-to-pdf')"
    />

    <SectionBlock
      body-white
      class="mb-4"
    >
      <template #title>
        GL-Score
      </template>
      <template #body>
        <ScoreChartWrapper
          :has-funds-data="hasTxs"
          :incoming-sources="allDataSource.incoming"
          :loading="calculationLoading"
          :outgoing-sources="allDataSource.outgoing"
          :profile="profileInfo2"
          :score="totalFunds"
        />
      </template>
    </SectionBlock>

    <SectionBlock
      body-white
      class="mb-4"
    >
      <template #title>
        Profile
      </template>
      <template #body>
        <ProfileInfo
          :loading="addressDataLoading"
          :profile-info="profileInfo2"
        />
      </template>
    </SectionBlock>

    <SectionBlock
      v-if="showBarsSection"
      body-white
      class="mb-4"
    >
      <template #title>
        Risk Exposure
      </template>
      <template #body>
        <ReportBarsWrapper
          :all-data-source="allDataSource"
          :all-data-source-by-owner="allDataSourceByOwner"
          :loading="calculationLoading"
          :pure-sources="pureSources"
          :selected-token="selectedToken"
        />
      </template>
    </SectionBlock>

    <ReportTablesWrapper
      :currencies-by-direction="currenciesByDirection"
      :loading="calculationLoading"
      :pure-sources="pureSources"
      report-type="address"
    />
  </div>
</template>

<script>
// Components
import SectionBlock from '@/components/gl-section-block'
import ReportBarsWrapper from '@/pages/report/components/bars/ReportBarsWrapper'
import ReportTablesWrapper from '@/pages/report/components/table/ReportTablesWrapper'
import ScoreChartWrapper from '@/pages/report/components/chart/ScoreChartWrapper'
import AddressInfoWrapper from '@/pages/report/components/info/AddressInfoWrapper'
import TopAddressActions from '@/pages/report/components/info/TopAddressActions'
import BottomInfoActions from '@/pages/report/components/info/BottomInfoActions'
import ProfileInfo from '@/pages/report/components/profile-info/ProfileInfo'
// Utils
import appConfig from '@/utils/appConfig'
// Vuex
import { mapState } from 'vuex'
//mixins
import deviceWidthMixin from '@/assets/mixins/deviceWidthMixin'

export default {
  components: {
    ReportBarsWrapper,
    ReportTablesWrapper,
    SectionBlock,
    ScoreChartWrapper,
    AddressInfoWrapper,
    TopAddressActions,
    BottomInfoActions,
    ProfileInfo,
  },
  mixins: [deviceWidthMixin],
  props: {
    sourceConfigsByDirection: {
      type: Object,
      default: () => ({}),
    },
    addressData: {
      type: Object,
      default: () => ({}),
    },
    addressInfo2: {
      type: Object,
      default: () => ({}),
    },
    profileInfo2: {
      type: Object,
      default: () => ({}),
    },
    allDataSource: {
      type: Object,
      default: () => ({}),
    },
    allDataSourceByOwner: {
      type: Object,
      default: () => ({}),
    },
    pureSources: {
      type: Object,
      default: () => ({}),
    },
    currenciesByDirection: {
      type: Object,
      default: () => ({}),
    },
    addressDataLoading: {
      type: Boolean,
      default: false,
    },
    calculationLoading: {
      type: Boolean,
      default: false,
    },
    totalFunds: {
      type: [Number, String],
      default: '',
    },
    selectedToken: {
      type: Object,
      default: () => ({}),
    },
    downloadLoading: {
      type: Boolean,
      default: false,
    },
    enableDownloadItems: {
      type: Array,
      Object,
      default: () => [],
    },
  },
  data() {
    return {
      RISK_POINT: 55,
    }
  },
  computed: {
    appConfig() {
      return appConfig
    },
    ...mapState('analytics', ['coinData']),
    hasTxs() {
      return Boolean(
        this.pureSources?.incoming?.length ||
          this.pureSources?.outgoing?.length,
      )
    },
    showBarsSection() {
      return Boolean(this.calculationLoading || this.hasTxs)
    },
  },
  methods: {
    explore(address) {
      const { href } = this.$router.resolve({
        name: 'analytics',
        query: { address, type: this.coinData.key },
      })
      window.open(href, '_blank')
    },
  },
}
</script>

<style>
.report-top-actions__wrapper {
  margin-bottom: 25px;
}
</style>
